import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/realname',
    children:[{
      path:'/realname',
      name:"realname",
      component:()=> import("../views/RealName.vue")
    },{
      path:'/account',
      name:"account",
      component:()=> import("../views/Account.vue")
    },{
      path:'/contract',
      name:"contract",
      component:()=> import("../views/Contract.vue")
    },{
      path:'/recharge',
      name:"recharge",
      component:()=> import("../views/Recharge.vue")
    }]
  },
  {
    path:'/login',
    name:"login",
    component:Login
  },
  {
    path:'/register',
    name:"register",
    component:Register
  }
  // ,
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

let originPush = VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}


export default router
