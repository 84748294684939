<template>
  <div class="home">
    <div class="home-head">
      <div class="fl">
        <img src="@/assets/logo.png" alt="">
      </div>

      <div class="fr">
        <!-- <img src="@/assets/service.png" alt=""> -->
        <div style="margin: 0 10px;">{{ nickname }}</div>
        <el-button type="primary" @click="logout">退出</el-button>
        <div></div>
      </div>


    </div>

    <!-- 主体 -->
    <div class="main-body">
      <div class="main-fl">
        <!-- 菜单 -->
        <div class="menu-contact">
          <div class="menu-item " :class="index == 1 ? 'menu-active' : ''" @click="menu(1, '/')">
            <div class="icon-img">
              <img src="@/assets/realname.png" alt="">
            </div>
            <span>手机实名检测</span>
          </div>

          <div class="menu-item" :class="index == 2 ? 'menu-active' : ''" @click="menu(2, '/recharge')">
            <div class="icon-img">
              <img src="@/assets/recharge.png" alt="">
            </div>
            <span>手机实名检测充值</span>
          </div>


          <div class="menu-item" style="margin-top: 30px;" :class="index == 3 ? 'menu-active' : ''"
            @click="menu(3, '/account')">
            <div class="icon-img">
              <img src="@/assets/account.png" alt="">
            </div>
            <span>游戏账号换绑监测</span>
          </div>


          <div class="menu-item" :class="index == 4 ? 'menu-active' : ''" @click="menu(4, '/contract')">
            <div class="icon-img">
              <img src="@/assets/contract.png" alt="">
            </div>
            <span>立刻签-电子合同</span>
          </div>


        </div>

        <!-- 二维码 -->
        <div class="qr-contact">
          <div class="qr-code">
            <div class="code">
              <img :src="pic" alt="">
            </div>
            <span style="margin-top: 8px;">客服微信</span>
            <span style="margin-top: 8px;">{{ tel }}</span>
          </div>


          <div class="shanghai">
            上海谦曰集团
          </div>
        </div>
      </div>
      <div class="main-fr">
        <router-view></router-view>
      </div>
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import { getConnect  } from "@/api/login"

export default {
  name: 'HomeView',
  data() {
    return {
      nameInput: '',
      phoneInput: '',
      nickname: localStorage.getItem('nickname'),
      tableData: [
        { date: 1 },
        { date: 2 },
        { date: 3 },
        { date: 4 },
        { date: 5 }
      ],
      currentPage: 1,
      index: localStorage.getItem('menuIndex') || 1,
      pic:'',
      tel:''
    }
  },
  mounted(){
    console.log("0000",window.location.hash);
    if('#/realname' == window.location.hash) this.index = 1
    this.$bus.$on('menuBus',this.busMenu)
    this.getConnect()
  },
  methods: {
    getConnect(){
      getConnect().then(res=>{
        console.log(res);
        if(res.code == 1){
          this.pic = res.data.pic
          this.tel = res.data.tel 
        }
      }).catch(error=>{})
    },
    logout() {
      this.$router.push("/login")
      localStorage.clear()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    busMenu(data){
      this.menu(data.index,data.path)
    },
    menu(index, path) {
      this.index = index
      localStorage.setItem('menuIndex', index)
      this.$router.push(path)
      if (index == 3) return window.open('https://jc.likeqian.co/', '_blank');
      if (index == 4) return window.open('https://likeqian.co/', '_blank');





    }
  },

}
</script>
<style lang="less">
.home {
  height: 100%;
}

.home-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  box-shadow: 0px 0px 14px 0px rgba(31, 94, 172, 0.17);

  .fl {
    // width: 244px;
    // height: 38px;

    width: 206px;
    height: 50px;
    margin-left: 56px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .fr {
    display: flex;
    align-items: center;
    margin-right: 24px;

    img {
      width: 58px;
      height: 57px;
    }

    // span {
    //   margin-left: 12px;
    // }

  }
}


.qr-contact {
  margin-top: 20px;

  .code {
    width: 131px;
    height: 131px;

    // background-color: #4978e7;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .qr-code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }

  .shanghai {
    margin-top: 30px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    text-align: center;
    font-weight: 400;
    color: #000000;
  }
}

.main-body {
  display: flex;
  height: calc(100% - 95px);
  background-color: #edf0f9;

  .main-fl {
    width: 269px;
    background-color: #ffffff;
  }

  .main-fr {
    width: 78%;
    height: 94%;
    border-radius: 12px;
    margin-left: 30px;
    margin-top: 22px;
    background-color: #ffffff;
  }
}

.menu-contact {
  padding-top: 38px;

  .menu-item {
    display: flex;
    height: 68px;

    // justify-content: center;
    align-items: center;
    // padding-left: 60px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

    cursor: pointer;

    .icon-img{
      width: 24px;
      height: 24px;
      margin-left: 60px;
      margin-right: 4px;
      img{
        width: 100%;
        height: 100%;
      }
    }

  }

  .menu-active {
    // 4978e7
    background-color: #f0faff;
    color: #4978E7;
    border-right: 2px solid #4978e7;
  }



}
</style>