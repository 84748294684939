import axios from 'axios'
import router from '@/router'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['token'] = localStorage.getItem("token") // 让每个请求携带自定义token 请根据实际情况自行修改
  return config
}, error => {
  // console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
// console.log(res);
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  if (code === 401) {
    router.push('/login')
    // location.href = '/login';
    // if (!isRelogin.show) {
    //   isRelogin.show = true;
    //   MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
    //     isRelogin.show = false;
    //     store.dispatch('LogOut').then(() => {
    //       location.href = '/index';
    //     })
    //   }).catch(() => {
    //     isRelogin.show = false;
    //   });
    // }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  }

  return res.data

},
  error => {
    // console.log('err' + error)
    if(error == 'AxiosError: Request failed with status code 401'){
      // console.log("===");
      // location.href = '/login';
      router.push('/login')
    }
    return Promise.reject(error)
  }
)


export default service
