<template>
  <div class="login-contact">
    <div class="logo">
      <div class="logo42">LOGO</div>
      <div class="logo22">LIKEQIAN</div>
    </div>



    <div class="login-content">
      <div class="img-contact">
        <img src="@/assets/login-img.png" alt="">
      </div>
      <div class="form-contact">
        <h1 class="login-title">注册游戏账号安全监测平台</h1>

        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="请输入立刻签账号">

            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="请输入密码">

            </el-input>
          </el-form-item>

          <el-form-item prop="confirmPassword">
            <el-input v-model="loginForm.confirmPassword" type="password" auto-complete="off" placeholder="请输入确认密码"
              @keyup.enter.native="handleLogin">

            </el-input>
          </el-form-item>


          <el-form-item prop="captcha" v-if="captchaEnabled">
            <el-input v-model="loginForm.captcha" auto-complete="off" placeholder="验证码" style="width: 63%"
              @keyup.enter.native="handleLogin">

            </el-input>
            <div class="login-code" v-show="codeUrl">
              <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </div>
          </el-form-item>

          <div class="register-contact">
            <!-- <div class="register">登录</div> -->
            <router-link class="register" :to="'/login'">登 录</router-link>
            <div class="forget">忘记密码?</div>
          </div>

          <el-form-item style="width:100%;">
            <el-button class="login-btn" :loading="loading" size="medium" type="primary" style="width:100%;"
              @click.native.prevent="handleLogin">
              <span v-if="!loading">注 册</span>
              <span v-else>注 册 中...</span>
            </el-button>
          </el-form-item>
        </el-form>

        <div class="agreement-contact">
          <div class="circle" :class="isAgreement ? 'is-circle' : ''" @click="agreementClick"></div>
          <div style="margin-left: 10px;">我已阅读并同意</div>
          <span class="agreement">《使用协议》</span>
          <div>和</div>
          <span class="agreement">《隐私协议》</span>
        </div>


      </div>
    </div>


    <div class="copyright">©2023上海谦曰科技集团有限公司</div>

    <div class="kforphone">
      <div class="kf-contact">
        <img src="@/assets/service.png" alt="">
        <span class="text">在线客服</span>

        <div class="content-scan">
          <div class="code-box-new">
            <img :src="pic" alt="">
          </div>
          <div class="desc">客服微信</div>
        </div>
      </div>
      <div class="phone-contact" style="margin-top: 10px;"  :title="tel" @click="copyText(tel)">
        <img src="@/assets/phone.png" alt="">
        <span class="text">咨询电话</span>
      </div>
    </div>

  </div>
</template>

<script>
import { register, generate,getConnect  } from "@/api/login"
import { Message } from 'element-ui';
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.loginForm.confirmPassword !== "") {
          this.$refs.loginForm.validateField("confirmPassword");
        }
        callback();
      }
    }

    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    }

    return {
      codeUrl: '',
      loginForm: {
        username: "",
        password: "",
        captcha: "",
        confirmPassword: ''
      },

      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: validatePass2, trigger: "blur" }
        ],
        captcha: [{ required: true, trigger: "change", message: "请输入验证码" }]
      },
      // 验证码开关
      captchaEnabled: true,
      // 协议
      isAgreement: false,
      loading: false,

      pic:'',
      tel:''
    }
  },
  created() {
    this.getCode();
    // this.getCookie();
    this.getConnect()
  },
  methods: {
    getConnect(){
      getConnect().then(res=>{
        console.log(res);
        if(res.code == 1){
          this.pic = res.data.pic
          this.tel = res.data.tel 
        }
      }).catch(error=>{})
    },
    //这个是写的公共方法
    copyText(value) {
      const aux = document.createElement('input')
      aux.setAttribute('value', value)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)
      this.$message.success('复制成功')
    },

    getCode() {
      // this.codeUrl = `http://43.134.16.51/api/user/generate?time=${new Date().getTime()}`
      generate().then(res => {
        // console.log(res);
        this.codeUrl = res
      })
    },
    agreementClick() {
      console.log(this.isAgreement);
      this.isAgreement = !this.isAgreement
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          let { username, password, captcha } = this.loginForm

          register({ username, password, captcha }).then(res => {
            this.loading = false;
            if (res.code == 0) {
              Message.error(res.msg)
              this.loginForm.captcha = ''
              this.getCode()
            }
            if (res.code == 1) {
              Message.success(res.msg)
              this.$router.push('/login')
            }
          })

          // this.$store.dispatch("Login", this.loginForm).then(() => {
          //   this.$router.push({ path: this.redirect || "/" }).catch(() => { });
          // }).catch(() => {
          //   this.loading = false;
          //   if (this.captchaEnabled) {
          //     this.getCode();
          //   }
          // });


        }
      });
    }
  }
}
</script>

<style lang="less">
.login-contact {
  background-color: #FAFBFF;
  height: 100%;

  .el-button--primary:focus,
  .el-button--primary:hover {
    background: linear-gradient(-53deg, #FFD1FC, #FAD0C6);
    border-color: #ffffff;
    color: #FFF;
  }
}

.logo {
  position: absolute;
  width: 330px;
  margin: 50px;

  .logo42 {
    font-size: 42px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #4078F7;
  }

  .logo22 {
    font-size: 22px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #4078F7;
  }
}


.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .img-contact {
    width: 340px;
    height: 384px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .form-contact {
    width: 460px;
    // height: 688px;
    background-color: #ffffff;
    margin-left: 258px;
    padding: 0 54px;
    padding-bottom: 40px;

    .login-title {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #000000;
      padding-top: 16px;
      margin: 0;
    }
  }
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  // width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 58px;

    input {
      height: 58px;
      border: 0;
      background-color: #F8F8F8;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }

  .login-code {
    width: 35%;
    height: 56px;
    float: right;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      vertical-align: middle;
    }
  }


  .register-contact {
    display: flex;
    // justify-content: center;
    align-items: center;
    height: 50px;
    padding-bottom: 50px;

    .register {

      display: flex;
      justify-content: center;
      align-items: center;

      width: 62px;
      height: 32px;
      background: #E3EDFF;
      border-radius: 16px;

      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #AEC6FA;

      text-decoration: none;
    }

    .forget {
      margin-left: 16px;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #858585;
    }


  }

  .login-btn {
    height: 63px;
    font-size: 23px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    border: #ffffff;
    background: linear-gradient(-53deg, #FFD1FC, #FAD0C6);
  }
}


.agreement-contact {
  display: flex;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #999999;
  padding-left: 25px;


  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ECECEC;
    cursor: pointer;
  }

  .is-circle {
    background-color: #3173DD;
  }

  .agreement {
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #3173DD;
    cursor: pointer;
  }

}

.copyright {
  position: absolute;
  bottom: 20px;
  left: 40%;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #B9B9B9;
}


.kforphone {
  position: absolute;
  top: 36%;
  right: 20px;

  .kf-contact,
  .phone-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 81px;
    height: 81px;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 8px 17px 0px rgba(31, 94, 172, 0.16);

    img {
      width: 48px;
      height: 48px;
    }

    .text {
      margin-top: 10px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #002365;
    }
  }


  .kf-contact:hover .content-scan {
    display: block;
  }

  .content-scan {
    position: absolute;
    right: 130px;
    bottom: 0;
    display: none;
    width: 152px;
    height: 176px;
    background: #fff;
    border-radius: 8px;

    padding: 16px;
    box-shadow: 0px 0px 40px rgba(79, 122, 253, 0.15);
    -webkit-box-shadow: 0px 0px 40px rgba(79, 122, 253, 0.15);

    .code-box-new {
      width: 150px;
      height: 150px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .desc {
      font-weight: 400;
      font-size: 12px;
      margin-top: 8px;
      text-align: center;
    }
  }


}
</style>