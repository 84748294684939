import request from '@/utils/request'

// 验证码
export function generate(query) {
    return request({
      url: '/api/user/generate',
      method: 'get',
      params: query
    })
  }


  // /api/user/register
  export function register(data) {
    return request({
      url: '/api/user/register',
      method: 'post',
      data: data
    })
  }

  export function login(data) {
    return request({
      url: '/api/user/login',
      method: 'post',
      data: data
    })
  }

  // https://2.likeqian.co/api/index/getConnect

  export function getConnect(data) {
    return request({
      url: '/api/index/getConnect',
      method: 'post',
      data: data
    })
  }